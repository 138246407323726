import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import { PersistGate } from 'redux-persist/integration/react'

// Css
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/animate.css/animate.css';
import '../node_modules/slick-carousel/slick/slick.css';
import '../node_modules/react-circular-progressbar/dist/styles.css';
import './assets/css/font-awesome.css';
import './assets/font/flaticon.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import './index.css';

import lag_es from './components/lang/es'
import lag_en from './components/lang/en'
import { store, persistor } from './reducers'
// import worker_script from './reducers/auth-worker';


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      es: lag_es,
      en: lag_en
    },
    lng: "es", // if you're using a language detector, do not define the lng option
    fallbackLng: "es",
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });


ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
  ,
  document.getElementById('main')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// try {

//   // const worker = new Worker(new URL('./reducers/auth-worker.js', import.meta.url));
//   const worker = new Worker(worker_script);

//   worker.onmessage = (m) => {
//     console.log("msg from worker: ", m.data);
//     //disparar log out cuando pase el tiempo de inactividad
//     // store.dispatch({type: "TEST-CALL-STORE"})
//   };
//   worker.postMessage('im from main');
// } catch (error) {

// }